body {
  background-color: #000 !important;
}

#word-sphere {
  position: relative;
  width: 420px;
  height: 420px;
}

#word-sphere div {
  position: absolute;
  top: 0px;
  left: 0px;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 6px;
}

input:-webkit-autofill {
  -webkit-transition-delay: 9999999s;
}
